<template>
  <v-card class="text-body-1 px-3 py-2" flat outlined>
    <v-card-title class="d-flex align-center mb-2 pa-0">
      <div class="font-weight-bold primary--text">
        {{ orderPolicyArea.name }}
        <span class="black--text font-weight-regular"
          >({{ orderPolicyArea.status === 1 ? "Bao gồm" : "Loại trừ" }})</span
        >
      </div>
      <v-spacer></v-spacer>
      <v-btn color="red accent-2" small text @click="removeArea">Xóa</v-btn>
    </v-card-title>

    <div>
      <span class="font-weight-medium mr-1">Tỉnh/Thành phố:</span>
      <span>{{ orderPolicyArea.tinh_thanhpho }}</span>
    </div>
    <div class="mt-1">
      <span class="font-weight-medium mr-1">Quận/huyện:</span>
      <span v-for="(item, index) in orderPolicyArea.quan_huyens" :key="index"
        >{{ item }}
        <span v-if="index < orderPolicyArea.quan_huyens.length - 1"
          >,
        </span></span
      >
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    orderPolicyArea: {
      type: Object,
      required: true
    },
    orderPolicyAreaIndex: {
      type: Number,
      required: true
    }
  },

  computed: {
    orderPolicy() {
      return this.$store.getters["ORDER_POLICY/orderPolicy"];
    }
  },

  methods: {
    removeArea() {
      this.orderPolicy.areas.splice(this.orderPolicyAreaIndex, 1);
    }
  }
};
</script>
