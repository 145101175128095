<template>
  <tp-modal name="modal-form-area" max-width="560px">
    <v-card flat>
      <!-- Start: Title and actions -->
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          Khu vực giao hàng
        </div>

        <v-spacer></v-spacer>

        <v-btn
          class="cyan lighten-5 rounded-lg mr-2"
          color="primary"
          :loading="
            orderPolicyAreaStatusRequest.value ===
              'loading-createOrderPolicyArea'
          "
          text
          @click="
            orderPolicyAreaStatusRequest.value ===
            'loading-createOrderPolicyArea'
              ? null
              : createOrderPolicyArea()
          "
        >
          Thêm
        </v-btn>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- End: Title and actions -->
      <!-- Start: Main content -->
      <v-container class="grey lighten-3 pa-5" fluid>
        <div class="amber lighten-5 text-body-2 px-3 py-2">
          Nếu không thêm khu vực cụ thể, lựa chọn giao tận nơi sẽ mặc định hỗ
          trợ mọi địa điểm trên toàn lãnh thổ Việt Nam
        </div>

        <v-card class="px-5 py-4 mt-5" flat>
          <div>
            <div class="font-weight-bold mb-2">Tên khu vực</div>
            <v-text-field
              v-model="orderPolicyArea.name"
              class="text-body-1"
              dense
              hide-details
              outlined
              placeholder="Nhập tên khu vực"
            ></v-text-field>
          </div>

          <v-radio-group
            v-model="orderPolicyArea.status"
            class="pt-0"
            dense
            hide-details
            row
          >
            <v-radio
              class="font-weight-bold"
              label="Bao gồm"
              :value="1"
            ></v-radio>
            <v-radio
              class="font-weight-bold"
              label="Loại trừ"
              :value="2"
            ></v-radio>
          </v-radio-group>

          <v-select
            v-model="orderPolicyArea.tinh_thanhpho"
            class="tp-filter-autocomplete text-body-1 mt-4"
            clearable
            dense
            hide-details
            hide-selected
            outlined
            :items="cities"
            item-text="name"
            item-value="name"
            :menu-props="{ nudgeTop: '-40px' }"
            placeholder="Chọn tỉnh/thành phố"
            single-line
          ></v-select>

          <div class="select-districts mt-5">
            <div class="text-h6 font-weight-bold mb-2">
              {{ orderPolicyArea.name }}
              <span class="font-weight-regular">(Không bắt buộc)</span>
            </div>

            <div class="select-districts__checkboxs">
              <div
                v-if="districts.length === 0"
                class="grey--text text--darken-2"
              >
                Chọn tỉnh/thành phố để xem danh sách quận/huyện
              </div>

              <template v-else>
                <v-checkbox
                  v-model="orderPolicyArea.quan_huyens"
                  v-for="(item, index) in districts"
                  dense
                  :key="item.id"
                  :class="index > 0 ? 'mt-2' : 'mt-0'"
                  hide-details
                  :label="item.name"
                  :value="item.name"
                ></v-checkbox>
              </template>
            </div>
          </div>
        </v-card>
      </v-container>
      <!-- End: Main content -->
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  computed: {
    cities() {
      return this.$store.getters["LOCAL_ADMINISTRATION/cities"];
    },

    districts() {
      return this.$store.getters["LOCAL_ADMINISTRATION/districts"];
    },

    orderPolicy() {
      return this.$store.getters["ORDER_POLICY/orderPolicy"];
    },

    orderPolicyArea() {
      return this.$store.getters["ORDER_POLICY_AREA/orderPolicyArea"];
    },

    orderPolicyAreaStatusRequest() {
      return this.$store.getters["ORDER_POLICY_AREA/statusRequest"];
    }
  },

  watch: {
    "orderPolicyArea.tinh_thanhpho"(val) {
      if (val) {
        this.getDistrictsOfCity(val);
      }
    }
  },

  async created() {
    await this.$store.dispatch("LOCAL_ADMINISTRATION/getCities");
  },

  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-form-area" });

      this.$store.dispatch("LOCAL_ADMINISTRATION/resetDistricts");
    },

    async createOrderPolicyArea() {
      await this.$store.dispatch("ORDER_POLICY_AREA/createOrderPolicyArea", {
        ...this.orderPolicyArea
      });

      if (
        this.orderPolicyAreaStatusRequest.value ===
        "success-createOrderPolicyArea"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm mới khu vực thành công"
          }
        });

        this.orderPolicy.areas.push({ ...this.orderPolicyArea });

        this.closeModal();
      } else if (
        this.orderPolicyAreaStatusRequest.value ===
        "error-createOrderPolicyArea"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">Tạo khu vực không thành công</span>`,
            message: this.orderPolicyAreaStatusRequest.message,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async getDistrictsOfCity(city) {
      await this.$store.dispatch(
        "LOCAL_ADMINISTRATION/getDistrictsOfCity",
        city
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.select-districts__checkboxs {
  height: 260px;
  overflow-y: auto;
}
</style>
