<template>
  <div>
    <div class="text-h6 font-weight-bold">Khách được hỗ trợ</div>

    <div class="mt-4">
      <div class="font-weight-bold mb-2">Các loại phí</div>

      <form-customer-support-fee
        v-for="(item, index) in orderPolicy.customer_gifts.slice(0, 5)"
        :class="{ 'mt-2': index > 0 }"
        :index="index"
        :key="item.type"
        :label="feeTypes[index]"
      ></form-customer-support-fee>
    </div>

    <div class="mt-5">
      <div class="font-weight-bold mb-2">Thời gian giữ máy cho khách</div>
      <v-select
        v-model="orderPolicy.customer_gifts[5].tk_value"
        class="text-body-1"
        dense
        :items="holdingTimes"
        item-text="name"
        item-value="id"
        :menu-props="{ nudgeTop: '-40px' }"
        outlined
        placeholder="Chọn thời gian"
      ></v-select>
    </div>
  </div>
</template>

<script>
import FormCustomerSupportFee from "./FormCustomerSupportFee";
import { LIST_HOLDING_TIMES } from "@/modules/Policy/constants";

export default {
  components: { FormCustomerSupportFee },

  data() {
    return {
      feeTypes: [
        "Phí giữ máy",
        "Phí thanh toán",
        "Phí chuyển kho",
        "Phí giao hàng tận nơi",
        "Phí thu hộ"
      ],
      holdingTimes: LIST_HOLDING_TIMES
    };
  },

  computed: {
    orderPolicy() {
      return this.$store.getters["ORDER_POLICY/orderPolicy"];
    }
  }
};
</script>
