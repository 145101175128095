<template>
  <div class="text-body-1">
    <div class="text-h6 font-weight-bold">Yêu cầu thanh toán</div>

    <div class="mt-4">
      <div class="font-weight-bold mb-2">Chọn yêu cầu thanh toán</div>
      <v-select
        v-model="orderPolicy.payment_condition.type"
        class="text-body-1"
        dense
        :items="paymentConditions"
        item-text="name"
        item-value="id"
        :menu-props="{ nudgeTop: '-40px' }"
        no-data-text="Không có dữ liệu"
        outlined
        placeholder="Chọn yêu cầu thanh toán"
      ></v-select>
    </div>

    <v-divider class="mb-4"></v-divider>

    <template v-if="orderPolicy.payment_condition.type === 1">
      <div>
        <div class="font-weight-bold mb-2">Đặt cọc theo</div>
        <v-radio-group
          v-model="orderPolicy.payment_condition.deposit_details.type"
          class="mt-0 pt-0"
          dense
          hide-details
          row
        >
          <v-radio
            class="font-weight-bold"
            label="Giá trị đơn hàng"
            :value="1"
          ></v-radio>
          <v-radio
            class="font-weight-bold"
            label="Giá trị SKU"
            :value="2"
          ></v-radio>
        </v-radio-group>

        <div class="my-2">Yêu cầu đặt cọc khi giá trị đơn hàng từ</div>
        <div class="d-flex">
          <tp-input-price
            v-model="orderPolicy.payment_condition.deposit_details.order_min"
            custom-class="mt-0 pt-0"
            custom-style="width: 200px"
            :empty-value="null"
            hide-details="auto"
            placeholder="Giá trị đơn hàng"
          ></tp-input-price>
          <div class="text-body-2 mx-3 pt-3">đến</div>
          <tp-input-price
            v-model="orderPolicy.payment_condition.deposit_details.order_max"
            custom-class="mt-0 pt-0"
            custom-style="width: 200px"
            :empty-value="null"
            hide-details="auto"
            placeholder="Giá trị đơn hàng"
          ></tp-input-price>
        </div>
      </div>

      <v-divider class="my-4"></v-divider>

      <div>
        <div class="font-weight-bold mb-2">Giá trị đặt cọc</div>
        <v-radio-group
          v-model="orderPolicy.payment_condition.deposit_details.value.type"
          class="mt-0 pt-0"
          dense
          hide-details
          row
          @change="onChangeDepositDetailType($event)"
        >
          <v-radio
            class="font-weight-bold"
            label="Số tiền cụ thể (VNĐ)"
            :value="1"
          ></v-radio>
          <v-radio
            class="font-weight-bold"
            label="Phần trăm (%)"
            :value="2"
          ></v-radio>
        </v-radio-group>

        <div class="my-2">Khách cần đặt cọc</div>

        <tp-input-price
          v-if="orderPolicy.payment_condition.deposit_details.value.type === 1"
          v-model="orderPolicy.payment_condition.deposit_details.value.value"
          custom-class="mt-0 pt-0"
          custom-style="width: 200px"
          :empty-value="null"
          placeholder="Nhập số tiền đặt cọc"
          validate="required"
        ></tp-input-price>

        <div style="width: 200px">
          <tp-text-field
            v-if="
              orderPolicy.payment_condition.deposit_details.value.type === 2
            "
            v-model="orderPolicy.payment_condition.deposit_details.value.value"
            placeholder="Giá trị"
            suffix="%"
            type="number"
            validate="required|minVal:0|maxVal:100"
          >
          </tp-text-field>
        </div>
      </div>

      <v-divider class="mb-4"></v-divider>

      <div class="text-body-2 grey--text text--darken-2">
        Tất cả SKU có mức độ hỗ trợ = 'Đặt cọc' sẽ được áp dụng yêu cầu thanh
        toán trên
      </div>
    </template>

    <template v-if="orderPolicy.payment_condition.type === 2">
      <div class="my-2">Không yêu cầu đặt cọc khi giá trị đơn hàng từ</div>
      <div class="d-flex">
        <tp-input-price
          v-model="orderPolicy.payment_condition.deposit_details.order_min"
          custom-class="mt-0 pt-0"
          custom-style="width: 200px"
          :empty-value="null"
          hide-details="auto"
          placeholder="Giá trị đơn hàng"
        ></tp-input-price>
        <div class="text-body-2 mx-3 pt-3">
          đến
        </div>
        <tp-input-price
          v-model="orderPolicy.payment_condition.deposit_details.order_max"
          custom-class="mt-0 pt-0"
          custom-style="width: 200px"
          :empty-value="null"
          hide-details="auto"
          placeholder="Giá trị đơn hàng"
        ></tp-input-price>
      </div>
    </template>
  </div>
</template>

<script>
import { LIST_PAYMENT_CONDITIONS } from "@/modules/Policy/constants";

export default {
  data() {
    return {
      paymentConditions: LIST_PAYMENT_CONDITIONS
    };
  },

  computed: {
    orderPolicy() {
      return this.$store.getters["ORDER_POLICY/orderPolicy"];
    }
  },

  methods: {
    onChangeDepositDetailType() {
      this.orderPolicy.payment_condition.deposit_details.value.value = null;
    }
  }
};
</script>
