<template>
  <div class="px-4">
    <div class="d-flex flex-row align-center mb-5">
      <div class="text-h5 font-weight-bold">
        {{
          orderPolicy.id
            ? "Cập nhật chính sách mua/đặt hàng từ xa"
            : "Thêm mới chính sách mua/đặt hàng từ xa"
        }}
      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-if="orderPolicy.id"
        class="red lighten-4 red--text text-accent-2 rounded-lg mr-3"
        depressed
        :loading="
          orderPolicyStatusRequest.value === 'loading-deleteOrderPolicy'
        "
        @click="deleteOrderPolicy"
      >
        Xóa
      </v-btn>

      <v-btn
        v-if="!orderPolicy.id"
        class="rounded-lg"
        color="primary"
        depressed
        :disabled="!policyFormValid"
        :loading="
          orderPolicyStatusRequest.value === 'loading-createOrderPolicy'
        "
        @click="
          orderPolicyStatusRequest.value === 'loading-createOrderPolicy'
            ? null
            : createOrderPolicy()
        "
      >
        Thêm
      </v-btn>

      <v-btn
        v-else
        class="rounded-lg"
        color="primary"
        depressed
        :disabled="!policyFormValid"
        :loading="
          orderPolicyStatusRequest.value === 'loading-updateOrderPolicy'
        "
        @click="
          orderPolicyStatusRequest.value === 'loading-updateOrderPolicy'
            ? null
            : updateOrderPolicy()
        "
      >
        Lưu
      </v-btn>
    </div>

    <v-form v-model="policyFormValid" ref="policyForm">
      <card-policy-info></card-policy-info>

      <v-card class="px-5 pt-1 mt-5" flat>
        <v-row class="mt-sm-5 pb-5">
          <v-col class="pr-sm-0 pt-sm-0 border-r" cols="12" sm="4">
            <col-sku-features class="pr-sm-8"></col-sku-features>
          </v-col>
          <v-col class="px-sm-8 pt-sm-0 border-r" cols="12" sm="4">
            <col-payment-requirement></col-payment-requirement>
          </v-col>
          <v-col class="px-sm-8 pt-sm-0" cols="12" sm="4">
            <col-customer-support></col-customer-support>
          </v-col>
        </v-row>
      </v-card>
    </v-form>

    <modal-form-area></modal-form-area>
  </div>
</template>

<script>
import CardPolicyInfo from "./components/CardPolicyInfo";
import ColCustomerSupport from "./components/ColCustomerSupport";
import ColPaymentRequirement from "./components/ColPaymentRequirement";
import ColSkuFeatures from "./components/ColSkuFeatures";
import ModalFormArea from "./modals/ModalFormArea";

export default {
  components: {
    CardPolicyInfo,
    ColCustomerSupport,
    ColPaymentRequirement,
    ColSkuFeatures,
    ModalFormArea
  },

  data() {
    return {
      policyFormValid: true
    };
  },

  computed: {
    orderPolicy() {
      return this.$store.getters["ORDER_POLICY/orderPolicy"];
    },
    orderPolicyStatusRequest() {
      return this.$store.getters["ORDER_POLICY/statusRequest"];
    }
  },

  async created() {
    const route = this.$route;

    if (route.params.orderPolicyId) {
      await this.$store.dispatch(
        "ORDER_POLICY/getOrderPolicyById",
        route.params.orderPolicyId
      );
    }
  },

  methods: {
    async createOrderPolicy() {
      // Validate
      await this.$refs.policyForm.validate();
      if (!this.policyFormValid) return;

      await this.$store.dispatch("ORDER_POLICY/createOrderPolicy", {
        ...this.orderPolicy
      });

      if (this.orderPolicyStatusRequest.value === "success-createOrderPolicy") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm mới chính sách thành công"
          }
        });

        await this.$router.push({
          name: "policy_order-policies-detail",
          params: {
            orderPolicyId: this.orderPolicy.id
          }
        });
      } else if (
        this.orderPolicyStatusRequest.value === "error-createOrderPolicy"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.orderPolicyStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async deleteOrderPolicy() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa chính sách <strong>"${this.orderPolicy.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch(
                "ORDER_POLICY/deleteOrderPolicy",
                this.orderPolicy.id
              );

              if (
                this.orderPolicyStatusRequest.value ===
                "success-deleteOrderPolicy"
              ) {
                await this.$store.dispatch("ORDER_POLICY/resetOrderPolicy");

                await this.$router.push({
                  name: "policy_order-policies",
                  query: {
                    search: routeQuery.search,
                    per_page: parseInt(routeQuery.limit),
                    cur_page: parseInt(routeQuery.page)
                  }
                });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa chính sách thành công"
                  }
                });
              } else if (
                this.orderPolicyStatusRequest.value ===
                "error-deleteOrderPolicy"
              ) {
                this.$modal.show({
                  name: "modal-alert",
                  payload: {
                    title: `<span class="red--text text--accent-2">Xóa không thành công</span>`,
                    message: this.orderPolicyStatusRequest.message,
                    cancelBtnText: "OK"
                  }
                });
              }
            }
          }
        }
      });
    },

    async updateOrderPolicy() {
      // Validate
      await this.$refs.policyForm.validate();
      if (!this.policyFormValid) return;

      await this.$store.dispatch("ORDER_POLICY/updateOrderPolicy", {
        ...this.orderPolicy
      });

      if (this.orderPolicyStatusRequest.value === "success-updateOrderPolicy") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật chính sách thành công"
          }
        });
      } else if (
        this.orderPolicyStatusRequest.value === "error-updateOrderPolicy"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.orderPolicyStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.border-r {
  @media only screen and (min-width: 960px) {
    border-right: 1px solid #e5e5e5;
  }
}
</style>
