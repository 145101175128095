<template>
  <v-card class="px-5" flat>
    <v-row>
      <v-col class="py-5" cols="3">
        <div class="text-h6 font-weight-bold">Thông tin chính sách</div>
      </v-col>

      <v-col class="pt-5 pb-4 pr-8" cols="4">
        <div class="font-weight-bold mb-2">Tên chính sách</div>
        <tp-text-field
          v-model="orderPolicy.name"
          :outlined="false"
          placeholder="Hãy bắt đầu bằng tiền tố Store- hoặc Delivery-"
          validate="required"
        ></tp-text-field>
      </v-col>

      <v-col class="pt-5 pb-4" cols="5">
        <div class="font-weight-bold mb-2">Mô tả ngắn</div>
        <tp-text-field
          v-model="orderPolicy.description"
          :outlined="false"
          placeholder="Mô tả ngắn để đồng nghiệp hiểu nội dung chính sách"
        ></tp-text-field>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  computed: {
    orderPolicy() {
      return this.$store.getters["ORDER_POLICY/orderPolicy"];
    }
  }
};
</script>
