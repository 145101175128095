<template>
  <div>
    <div class="text-h6 font-weight-bold">Đặc điểm SKU</div>

    <div class="mt-4">
      <div class="font-weight-bold mb-2">Tình trạng hàng</div>
      <v-select
        v-model="orderPolicy.product_condition"
        class="text-body-1 select__hide-input"
        dense
        :items="productStatusList"
        item-text="name"
        item-value="id"
        :menu-props="{ nudgeLeft: '4px' }"
        no-data-text="Không có dữ liệu"
        outlined
        placeholder="Chọn tình trạng hàng"
      >
        <template v-slot:selection="data">
          <span class="py-1">{{ data.item.name }}</span>
        </template>
      </v-select>
    </div>

    <div class="">
      <div class="font-weight-bold mb-2">Nơi nhận hàng</div>
      <v-radio-group
        v-model="orderPolicy.address_condition"
        class="mt-0 pt-0"
        dense
        hide-details
      >
        <v-radio
          class="font-weight-bold"
          label="Nhận tại cửa hàng"
          :value="1"
        ></v-radio>
        <v-radio
          class="font-weight-bold"
          label="Giao tận nơi"
          :value="2"
        ></v-radio>
        <v-radio
          class="font-weight-bold"
          label="Nhận tại cửa hàng và giao tận nơi"
          :value="3"
        ></v-radio>
      </v-radio-group>
    </div>

    <div v-if="orderPolicy.address_condition === 2" class="mt-3">
      <div>Khu vực cụ thể</div>

      <template v-if="orderPolicy.areas.length === 0">
        <v-card class="grey lighten-4 mt-2" flat>
          <div class="text-center py-6 grey--text text--darken-1">
            Chưa có khu vực nào
          </div>
        </v-card>
      </template>
      <template v-else>
        <card-area
          v-for="(area, index) in orderPolicy.areas"
          class="mt-2"
          :key="index"
          :order-policy-area="area"
          :order-policy-area-index="index"
        ></card-area>
      </template>

      <v-btn
        class="green lighten-5 mt-3"
        color="primary"
        text
        @click="openModalFormArea"
      >
        <v-icon left>mdi-plus</v-icon>
        Tạo khu vực
      </v-btn>

      <div class="mt-3 text-body-2 grey--text text--darken-2">
        Nếu không thêm khu vực cụ thể, lựa chọn giao tận nơi sẽ mặc định hỗ trợ
        mọi địa điểm trên lãnh thổ Việt Nam.
      </div>
    </div>
  </div>
</template>

<script>
import CardArea from "./CardArea";
import { LIST_PRODUCT_CONDITIONS } from "@/modules/Policy/constants";

export default {
  components: { CardArea },

  data() {
    return {
      productStatusList: LIST_PRODUCT_CONDITIONS
    };
  },

  computed: {
    orderPolicy() {
      return this.$store.getters["ORDER_POLICY/orderPolicy"];
    }
  },

  created() {
    this.$store.dispatch("ORDER_POLICY_AREA/getOrderPolicyAreas");
  },

  methods: {
    async openModalFormArea() {
      await this.$store.dispatch("ORDER_POLICY_AREA/resetOrderPolicyArea");
      this.$modal.show({ name: "modal-form-area" });
    }
  }
};
</script>

<style lang="scss" scoped>
.select__hide-input {
  &::v-deep .v-select__selections input {
    display: none;
  }
}
</style>
