<template>
  <div>
    <v-checkbox
      v-model="selected"
      class="mt-0"
      dense
      hide-details
      :label="label"
    ></v-checkbox>

    <v-radio-group
      v-if="selected"
      v-model="orderPolicy.customer_gifts[index].tk_type"
      class="ml-6 mt-2"
      dense
      hide-details
      @change="onTypeChange"
    >
      <v-radio
        class="font-weight-bold"
        label="Hỗ trợ % cụ thể"
        :value="1"
      ></v-radio>

      <tp-text-field
        v-if="orderPolicy.customer_gifts[index].tk_type === 1"
        v-model="orderPolicy.customer_gifts[index].tk_value"
        class="ml-8"
        placeholder="Nhập giá trị"
        suffix="%"
        type="number"
        validate="required|minVal:0|maxVal:100"
      ></tp-text-field>

      <v-radio
        class="font-weight-bold"
        label="Hỗ trợ số tiền cụ thể (VNĐ)"
        :value="2"
      ></v-radio>

      <tp-input-price
        v-if="orderPolicy.customer_gifts[index].tk_type === 2"
        v-model="orderPolicy.customer_gifts[index].tk_value"
        custom-class="ml-8"
        placeholder="Nhập số tiền"
        hide-details="auto"
        validate="required"
      ></tp-input-price>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },

  computed: {
    orderPolicy() {
      return this.$store.getters["ORDER_POLICY/orderPolicy"];
    },

    selected: {
      get() {
        return !!this.orderPolicy.customer_gifts[this.index].tk_type;
      },
      set(val) {
        if (val) {
          this.orderPolicy.customer_gifts[this.index].tk_type = 1;
        } else {
          this.orderPolicy.customer_gifts[this.index].tk_type = null;
          this.orderPolicy.customer_gifts[this.index].tk_value = null;
        }
      }
    }
  },

  methods: {
    onTypeChange() {
      this.orderPolicy.customer_gifts[this.index].tk_value = null;
    }
  }
};
</script>
